@import url('https://fonts.googleapis.com/css2?family=Balthazar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anybody:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anybody:ital,wght@0,100..900;1,100..900&family=Oooh+Baby&display=swap');
*{
  scroll-behavior: smooth;
}
.aboutstart{
   padding-top:30px;
   font-family: 'Balthazar', serif;
   font-weight:lighter;
   gap:50px;
   margin-top:40px;
  
}



.herostart{
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}


.aboutstart h1 {
  letter-spacing: 2px;  
  line-height: 1.5em;
  color:rgb(102, 91, 32);
  font-family:cinzel;
  font-size:3em;
  text-decoration: underline;
  padding:20px;
  margin-left:20px;
  
 

}
.aboutstart p{
    font-size:1em;
    line-height:1.3em;
    letter-spacing: 1.25px;
    padding:10px;
    text-align: justify;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#8c8b8b;
    max-width: 80%;
   
}
.aboutstart .paras1{
  display:flex;
}
.justspace{
 width:50%;
}

.contact-button{
  border:1px solid gold;
  padding:10px;
  color:rgb(150, 147, 147);
  text-decoration:none;
  letter-spacing: 2px;
  margin:20px;
  cursor:pointer;

}


/* #0f172a; */
.contact-button:hover{
  transition: 0.2s ease-in;
  font-weight: bold;
  color:rgb(90, 90, 90);
  
}


/* another starts */

.imagehoni{
  display:flex;
  flex-direction: column;
  width:80%;
  margin:0 auto;
  padding:10px;
  line-height:3.4em;
  letter-spacing: 1.6px;
  align-items:start;
  justify-content: start;

  
}
.imagehoni p{
  color:#646464;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:1.1em;
  font-size: clamp(1rem, 10vw, 1.7rem); 
  width:fit-content;
  padding:5px;
}

.imagehoni h1{
  font-size: clamp(1rem, 12vw, 7rem); 
  color:#ffffff;
  margin-top:20px;
}

.imagehoni h2{
  color:#c5c5c5;
  font-size: clamp(1rem, 10vw, 3rem); 
}
.centerhoni{
  width:100%;
  align-items: center;
  padding:30px;
  display:flex;
 
}


.link_wrapper{
  position: relative;
}

.bts{
  display: block;
  width:250px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: transparent;
  text-align: center;
  color: rgb(70, 63, 28);
  text-transform: uppercase;
  letter-spacing: 1px;
  /* border-bottom: 3px solid rgb(70, 63, 28);
  border-width:50%; */
  transition: all .35s;
  font-family: cinzel;
  font-size:clamp(1em, 13vw, 1.3em)
  

}

.icon{
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all .35s;
}

.icon svg{
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: rgb(70, 63, 28);
  transition: all .35s;
}

.bts:hover{
  width: 200px;
  border: 3px solid rgb(70, 63, 28);
  background: transparent;
  color: rgb(70, 63, 28);
}

.bts:hover + .icon{
  border: 3px solid rgb(70, 63, 28);
  right: -25%;
}




@media(max-width:780px){
  .imagehoni{
    border:none;
  }
  .imagehoni img{
    width:50%;
  }
  .aboutstart p{
    font-size:1em;
  }
}

