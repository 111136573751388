.icons-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
}

.icons-main i {
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.icons-main a {
  color: white;
}
.icons-main i:hover::after {
  font-family: 'Courier New', Courier, monospace;
  letter-spacing: 1px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(10px) translateY(-50%);
  font-size: 0.9em;
  color: #fff;
  padding: 5px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.icons-main a:hover .fa-linkedin-in::after {
  content: "LinkedIn";
}

.icons-main a:hover .fa-github::after {
  content: "GitHub";
}

.icons-main a:hover .fa-instagram::after {
  content: "Instagram";
}

.icons-main a:hover .fa-twitter::after {
  content: "Twitter";
}

.icons-main i:hover::after {
  opacity: 1;
}
