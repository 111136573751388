* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Alata", sans-serif;

}

body{
    background-color:#0e0e0f;
    color:white;
    font-weight:700;
    max-width:1200px;
    margin:0 auto;

}

.pro{
    text-align: center;
    padding:40px;
    font-size:3em;
    letter-spacing: 2px;
   
}
.heightdedo{
    height:3rem;
}
.vh{
    height:100vh;
}
@media(max-width:780px){
    .pro{
        padding:0;
    }
}


